import {
	mapState,
	mapActions
} from 'vuex'
import Vue from 'vue'
import {
	Popup,
	Toast,
	Dialog
} from 'vant'
import product from '../../utils/config.js'
// eslint-disable-next-line import/extensions
import con from '../../utils/common.js'
const {
	Base64
} = require('js-base64')
import md5 from 'js-md5'
import get from '../../utils/request'

Vue.use(Toast)
export default {
	name: 'wardetail',
	components: {

	},
	data() {
		return {
			detail: {},
			proct: 'TAHMB-01',
			beibaoList: [],
			isxian: false,
			istui: true,
			show: false,
			type: '',
			//是否在允许退款时间范围内
			fanwei: false,
			//全联订单号
			orderId: '',
		}
	},
	created() {
		var that = this
		var istime = setInterval(() => {
			var isshijian = (new Date(2021, 0, 23, 0, 0, 0)) - (new Date())
			if (isshijian < 0) {
				that.isxian = true
				clearInterval(istime)
			} else {
				that.isxian = false
			}

		}, 1000);

		var id = this.$route.query.id
		var type = this.$route.query.type;
		this.type = type;
		var orderType1 = this.$route.query.orderTypes
		this.orderId = this.$route.query.orderId;
		// that.orderType = orderType1;

		this.warDetail({
			ids: id,
			types: type,
			orderType: orderType1
		}).then(res => {
			if (res.code == 200) {
				this.detail = res.info
				this.beibaoList = res.info.bxOrderJson
				//商品销售退款允许时间
				var startc = this.gettimenumber(res.info.bxShop.standby3)
				var endc = this.gettimenumber(res.info.bxShop.standby4)
				var current = new Date().getTime()
				if (startc < current < endc) {
					this.fanwei = true
				} else {
					this.fanwei = false
				}
				const year = res.info.beginDate
					.trim()
					.split(" ")[0]
					.split("年")[0]

				const morth = res.info.beginDate
					.trim()
					.split(" ")[0]
					.split("年")[1].split("月")[0]

				const day = res.info.beginDate
					.trim()
					.split(" ")[0]
					.split("月")[1].split('日')[0]
				const hours = 0

				const fen = 0

				const miao = 0
				var ctimer = setInterval(() => {
					if (that.Calculate(year, morth, day, hours, fen, miao) <= 1000) {
						that.istui = false
					} else {
						that.istui = true
						clearInterval(ctimer)
					}
				}, 1000)
			}
		})
	},
	methods: {
		...mapActions('warranty', ['warDetail', 'refundOrder']),
		// 转换为时间戳
		Calculate(year, month, day, hour, minute, second) {
			var leftTime = ''
			return (leftTime =
				new Date(year, month - 1, day, hour, minute, second) - new Date())
		},
		/**
		 * 判断是否为空
		 * @param {Object} value
		 */
		isBlank(value) {
			value = value || ''
			if (!value || value == null || value.length < 1) {
				return true;
			}
			return false;
		},
		gettimenumber(date) {
			if (this.isBlank(date)) {
				return '';
			}
			date = date.substring(0, 19);
			date = date.replace(/-/g, '/');
			var timestamp = new Date(date).getTime();
			return timestamp;
		},
		toRefund(bxid) {
			Dialog.confirm({
					title: '提示',
					message: '确认申请退款？',
					confirmButtonText: "确定",
					className: 'sure'
				})
				.then(() => {
					Toast.loading({
						message: '退款中...',
						forbidClick: true,
						loadingType: 'spinner',
						duration: 0,
					})

					this.refundOrder({
						id: Base64.encode(Base64.encode(Base64.encode(bxid))),
						param: md5(Base64.encode(Base64.encode(Base64.encode(bxid))) + 'amt1NzZ0OTg=')
					}).then(res => {
						if (res.code == 200) {
							Toast.clear()
							Toast('退款成功')
							setTimeout(() => {
								this.$router.push({
									name: 'Warranty2'
								})
							}, 1000)
						} else {
							Toast(res.msg)
						}
					})
				})
				.catch(() => {

					// on cancel
				})
		},
		//跳转至退款页面
		joinToRefundPage(detail) {
			if (detail.standby1 === 'CZHKB-01' || detail.standby1 === 'CZHKB2024-01') {
				var messageTip = "确认申请退款？";
				Dialog.confirm({
						title: '提示',
						message: messageTip,
						confirmButtonText: "确定",
						className: 'sure'
					})
					.then(() => {
						let refundUrl = detail.mainUrl + '/view/refundReson.html?platOrderId=' + this.$route.query
							.id + "&token=" +
							localStorage.getItem('token');
						window.location.href = refundUrl;
					})
					.catch(() => {
						// on cancel
					})
				return;
			}
			Toast.clear()
			Toast('退款配置异常，请与管理员联系');
		},
		toRefundByTaiAn(bxid, paymode) {
			var messageTip = "";
			if (paymode == 3) {
				messageTip = "医保个账支付暂不支持部分退款，提交后支付订单的所有保费将全部原路退回，确定是否退款？";
			} else {
				messageTip = "确认申请退款？";
			}
			Dialog.confirm({
					title: '提示',
					message: messageTip,
					confirmButtonText: "确定",
					className: 'sure'
				})
				.then(() => {
					Toast.loading({
						message: '退款中...',
						forbidClick: true,
						loadingType: 'spinner',
						duration: 0,
					})

					this.refundOrder({
						id: Base64.encode(Base64.encode(Base64.encode(bxid))),
						param: md5(Base64.encode(Base64.encode(Base64.encode(bxid))) + 'amt1NzZ0OTg=')
					}).then(res => {
						if (res.code == 200) {
							Toast.clear()
							Toast('退款成功')
							setTimeout(() => {
								this.$router.push({
									name: 'Warranty2'
								})
							}, 1000)
						} else {
							Toast(res.msg)
						}
					})
				})
				.catch(() => {

					// on cancel
				})
		},
		back() {
			this.$router.go(-1)
		},
		tobaodan(url) {
			// this.$router.push({name:'openPdf',query:{pdf:url}})
			Toast.loading({
				message: '加载中...',
				duration: 50000,
				forbidClick: true,
			})
			window.location.href = url
			// Toast.clear();
		},
		toZhbUrl(url) {
			// this.$router.push({name:'openPdf',query:{pdf:url}})
			Toast.loading({
				message: '加载中...',
				duration: 50000,
				forbidClick: true,
			})
			window.location.href = url
			// Toast.clear();
		},
		toLhbUrl(obj) {
			Toast.loading({
				message: '加载中...',
				duration: 50000,
				forbidClick: true,
			})
			//this.$router.push({name:'openPdf',query:{pdf:con.xiaMenBaoDanUrl+"?cer="+encodeURIComponent(Base64.encode(obj))+"&planCode=XMLHB-01"}})
			window.location.href = con.xiaMenBaoDanUrl + "?cer=" + encodeURIComponent(Base64.encode(obj)) +
				"&planCode=XMLHB-01"
			// Toast.clear();
		},
		toCZHKBUrl(obj) {
			Toast.loading({
				message: '加载中...',
				duration: 50000,
				forbidClick: true,
			})
			window.location.href = con.chaozhouDownPdf + "CZHKB-01/" + Base64.encode(this.beibaoList[0].insurantCerNo ||
				this.beibaoList[0].certificateNo) + ".do"
			Toast.clear();
		},
		downfapiaoUrl() {
			Toast.loading({
				message: '加载中...',
				duration: 50000,
				forbidClick: true,
			})
			window.location.href = con.fapiaoDownPdf + "CZHKB-01/" + Base64.encode(this.beibaoList[0].insurantCerNo ||
				this.beibaoList[0].certificateNo) + ".do"
			Toast.clear();
		},
		toTahmb02Url(obj) {
			Toast.loading({
				message: '加载中...',
				duration: 50000,
				forbidClick: true,
			})
			//this.$router.push({name:'openPdf',query:{pdf:con.xiaMenBaoDanUrl+"?cer="+encodeURIComponent(Base64.encode(obj))+"&planCode=XMLHB-01"}})
			window.location.href = con.taiAn2022BaoDanUrl + obj + ".do"
			// Toast.clear();

			// get(con.taiAn2022BaoDanUrl +obj+".do").then(res=>{
			//   if(res.data.indexOf('https')>-1){
			//     window.location.href = res.data
			//   }else if(res.data===null){
			//     Toast('暂无保单')
			//   }else {
			//     Toast(res.data)
			//   }
			// })
		},

		toJcxUrl(obj) {
			Toast.loading({
				message: '加载中...',
				duration: 0,
				forbidClick: true,
			})
			//this.$router.push({name:'openPdf',query:{pdf:con.xiaMenBaoDanUrl+"?cer="+encodeURIComponent(Base64.encode(obj))+"&planCode=XMLHB-01"}})
			window.location.href = con.jiaCaiXianUrl + obj + ".do"
			// Toast.clear();
		},
		toLipei() {
			this.$router.push({
				name: 'claimType'
			})
		}
	},
}